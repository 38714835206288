// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---packages-gatsby-woocommerce-theme-src-pages-404-js": () => import("./../../../../packages/gatsby-woocommerce-theme/src/pages/404.js" /* webpackChunkName: "component---packages-gatsby-woocommerce-theme-src-pages-404-js" */),
  "component---packages-gatsby-woocommerce-theme-src-pages-cancel-js": () => import("./../../../../packages/gatsby-woocommerce-theme/src/pages/cancel.js" /* webpackChunkName: "component---packages-gatsby-woocommerce-theme-src-pages-cancel-js" */),
  "component---packages-gatsby-woocommerce-theme-src-pages-cart-js": () => import("./../../../../packages/gatsby-woocommerce-theme/src/pages/cart.js" /* webpackChunkName: "component---packages-gatsby-woocommerce-theme-src-pages-cart-js" */),
  "component---packages-gatsby-woocommerce-theme-src-pages-checkout-js": () => import("./../../../../packages/gatsby-woocommerce-theme/src/pages/checkout.js" /* webpackChunkName: "component---packages-gatsby-woocommerce-theme-src-pages-checkout-js" */),
  "component---packages-gatsby-woocommerce-theme-src-pages-contact-js": () => import("./../../../../packages/gatsby-woocommerce-theme/src/pages/contact.js" /* webpackChunkName: "component---packages-gatsby-woocommerce-theme-src-pages-contact-js" */),
  "component---packages-gatsby-woocommerce-theme-src-pages-order-details-js": () => import("./../../../../packages/gatsby-woocommerce-theme/src/pages/order-details.js" /* webpackChunkName: "component---packages-gatsby-woocommerce-theme-src-pages-order-details-js" */),
  "component---packages-gatsby-woocommerce-theme-src-pages-success-js": () => import("./../../../../packages/gatsby-woocommerce-theme/src/pages/success.js" /* webpackChunkName: "component---packages-gatsby-woocommerce-theme-src-pages-success-js" */),
  "component---packages-gatsby-woocommerce-theme-src-pages-wishlist-js": () => import("./../../../../packages/gatsby-woocommerce-theme/src/pages/wishlist.js" /* webpackChunkName: "component---packages-gatsby-woocommerce-theme-src-pages-wishlist-js" */),
  "component---packages-gatsby-woocommerce-theme-src-templates-archive-index-js": () => import("./../../../../packages/gatsby-woocommerce-theme/src/templates/archive/index.js" /* webpackChunkName: "component---packages-gatsby-woocommerce-theme-src-templates-archive-index-js" */),
  "component---packages-gatsby-woocommerce-theme-src-templates-front-page-index-js": () => import("./../../../../packages/gatsby-woocommerce-theme/src/templates/front-page/index.js" /* webpackChunkName: "component---packages-gatsby-woocommerce-theme-src-templates-front-page-index-js" */),
  "component---packages-gatsby-woocommerce-theme-src-templates-my-account-index-js": () => import("./../../../../packages/gatsby-woocommerce-theme/src/templates/my-account/index.js" /* webpackChunkName: "component---packages-gatsby-woocommerce-theme-src-templates-my-account-index-js" */),
  "component---packages-gatsby-woocommerce-theme-src-templates-page-index-js": () => import("./../../../../packages/gatsby-woocommerce-theme/src/templates/page/index.js" /* webpackChunkName: "component---packages-gatsby-woocommerce-theme-src-templates-page-index-js" */),
  "component---packages-gatsby-woocommerce-theme-src-templates-product-index-js": () => import("./../../../../packages/gatsby-woocommerce-theme/src/templates/product/index.js" /* webpackChunkName: "component---packages-gatsby-woocommerce-theme-src-templates-product-index-js" */),
  "component---packages-gatsby-woocommerce-theme-src-templates-single-post-index-js": () => import("./../../../../packages/gatsby-woocommerce-theme/src/templates/single-post/index.js" /* webpackChunkName: "component---packages-gatsby-woocommerce-theme-src-templates-single-post-index-js" */)
}

