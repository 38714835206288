module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-source-wordpress-experimental/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://admin.sergiosmarketplace.com/graphql","verbose":true,"develop":{"nodeUpdateInterval":30000,"hardCacheMediaFiles":true,"hardCacheData":false},"production":{"hardCacheMediaFiles":false,"allow404Images":false},"debug":{"graphql":{"showQueryOnError":false,"showQueryVarsOnError":true,"copyQueryOnError":true,"panicOnError":true,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"excludeFieldNames":["blocksJSON","saveContent"],"type":{"Post":{"limit":5000}}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby WooCommerce Theme","short_name":"Electra","start_url":"/","background_color":"#eaeaea","theme_color":"#1e1e1e","display":"standalone","icon":"/opt/build/repo/packages/gatsby-woocommerce-theme/src/images/sergios-s.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ea9545f226966dd5e616be6211d5d95e"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../packages/gatsby-woocommerce-theme/gatsby-browser.js'),
      options: {"plugins":[],"wordPressUrl":"https://admin.sergiosmarketplace.com","gatsbySiteUrl":"https://vibrant-stonebraker-cc7b65.netlify.app/","googleTagManagerId":"xxxx","fbAppId":"xxxx"},
    }]
